import { Button, message, Upload } from "antd";
import React, { useState } from "react";
import uploadbg from "../../assets/upload-04.svg";
import { getRequestData, saveRequestData } from "../../stores/formDataStore";
import { newRequest, UploadFile } from "../../services";

export default function AttachmentForm({ next, prev }) {
  const [awardFiles, setAwardFiles] = useState([]);

  const handleUploadChange = (info, fieldName) => {
    const { status } = info.file;

    if (status === "done") {
      if (info.file.response && info.file.response.result === false) {
        message.error(info.file.response.message);
      } else {
        const newAttachment = {
          name: fieldName,
          fileName: info.file.response.filename,
          type: info.file.response.mimetype,
          url: info.file.response.path,
          size: info.file.response.size,
        };

        setAwardFiles((prevAttachments) => {
          const updatedAttachments = [...prevAttachments, newAttachment];
          // : [newAttachment];

          console.log(updatedAttachments);
          console.log("UPDATED ATTACHMENTS FIRST", awardFiles);
          saveRequestData({ awardFiles: updatedAttachments });
          return updatedAttachments;
        });
        message.success("Файлыг амжилттай хууллаа");
        console.log("Updated attachments:", awardFiles);

        // setAttachments((prevData) => ({
        //   ...prevData,
        //   // [fieldName]: {
        //   name: fieldName,
        //   path: info.file.response.path,
        //   size: info.file.response.size,
        //   // },
        // }));
        // saveRequestData(attachments);
      }
    } else if (status === "error") {
      message.error("Файлыг хуулахад алдаа гарлаа");
    }
  };
  // function createRequest() {
  //   saveRequestData({ attachments });
  //   const formData = getRequestData();
  //   return newRequest(formData)
  //     .then((response) => {
  //       if (response.result) {
  //         message.success(response.message);
  //       } else {
  //         message.error(response.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  const handleSubmit = () => {
    const formData = getRequestData();
    console.log("aaaaaaaaaaaaaaaaaaaaa----------", formData);
    newRequest(formData)
      .then((response) => {
        if (response.result) {
          message.success(response.message);
          console.log("aaaaaaaaaaaaaaaaaaaaa----------", formData);
          next();
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("aaaaaaaaaaaaaaaaaaaaa");
      });
  };
  const handlePrev = () => {
    saveRequestData({ awardFiles: [] });
    prev();
  };

  const token = localStorage.getItem("token");
  return (
    <div className="grid grid-cols-2  gap-6">
      <div className="flex flex-col w-full">
        <label className=" text-[13px] font-medium leading-5 text-slate-700">
          Шагнуулахаар нэр дэвшүүлсэн тухай байгууллагын албан бичиг *
        </label>
        <Upload
          onChange={(info) => handleUploadChange(info, "albanBichig")}
          action={UploadFile()}
          headers={{
            Authorization: `Bearer ${token}`,
          }}
          name="file"
          // fileList={attachments["albanBichig"] || []}
          className="border bg-white/5 rounded-lg w-full h-5/6 py-2 px-3 mt-4 text-center "
          multiple
        >
          <div className="flex flex-col m-0 justify-center items-center gap-4 pb-2">
            <img src={uploadbg} alt="uploadicon" />
            <span className="text-[14px] font-normal leading-5 text-slate-500 text-center">
              Файл хуулах
            </span>
          </div>
        </Upload>
      </div>
      <div className="flex flex-col w-full">
        <label className=" text-[13px] font-medium leading-5 text-slate-700">
          Хамт олны эсхүл удирдах зөвлөлийн хурлын тэмдэглэл *
        </label>
        <Upload
          onChange={(info) => handleUploadChange(info, "hurTemdeglel")}
          action={UploadFile()}
          headers={{
            Authorization: `Bearer ${token}`,
          }}
          className="border bg-white/5 rounded-lg w-full h-5/6 py-2 px-3 mt-4 text-center "
          name="file"
          multiple
        >
          <div className="flex flex-col m-0 justify-center items-center gap-4 pb-2">
            <img src={uploadbg} alt="uploadicon" />
            <span className="text-[14px] font-normal leading-5 text-slate-500 text-center">
              Файл хуулах
            </span>
          </div>
        </Upload>
      </div>
      <div className="flex flex-col w-full">
        <label className=" text-[13px] font-medium leading-5 text-slate-700">
          Ажил байдлын тодорхойлолт /Бүтээлийн жагсаалт/ *
        </label>
        <Upload
          onChange={(info) => handleUploadChange(info, "todorhoilolt")}
          name="file"
          action={UploadFile()}
          headers={{
            Authorization: `Bearer ${token}`,
          }}
          className="border bg-white/5 rounded-lg w-full h-5/6 py-2 px-3 mt-4 text-center "
          multiple
        >
          <div className="flex flex-col m-0 justify-center items-center gap-4 pb-2">
            <img src={uploadbg} alt="uploadicon" />
            <span className="text-[14px] font-normal leading-5 text-slate-500 text-center">
              Файл хуулах
            </span>
          </div>
        </Upload>
      </div>
      <div className="flex flex-col w-full">
        <label className=" text-[13px] font-medium leading-5 text-slate-700">
          Нийгмийн даатгалын дэвтрийн хуулбар *
        </label>
        <Upload
          onChange={(info) => handleUploadChange(info, "ndsh")}
          name="file"
          action={UploadFile()}
          headers={{
            Authorization: `Bearer ${token}`,
          }}
          className="border bg-white/5 rounded-lg w-full h-5/6 py-2 px-3 mt-4 text-center "
          multiple
        >
          <div className="flex flex-col m-0 justify-center items-center gap-4 pb-2">
            <img src={uploadbg} alt="uploadicon" />
            <span className="text-[14px] font-normal leading-5 text-slate-500 text-center">
              Файл хуулах
            </span>
          </div>
        </Upload>
      </div>
      <div className="flex flex-col w-full">
        <label className=" text-[13px] font-medium leading-5 text-slate-700">
          Өмнө шагнагдсан шагналуудын хуулбар
        </label>
        <Upload
          onChange={(info) => handleUploadChange(info, "pastAwards")}
          name="file"
          action={UploadFile()}
          headers={{
            Authorization: `Bearer ${token}`,
          }}
          multiple
          className="border bg-white/5 rounded-lg w-full h-5/6 py-2 px-3 mt-4 text-center "
        >
          <div className="flex flex-col m-0 justify-center items-center gap-4 pb-2">
            <img src={uploadbg} alt="uploadicon" />
            <span className="text-[14px] font-normal leading-5 text-slate-500 text-center">
              Файл хуулах
            </span>
          </div>
        </Upload>
      </div>
      <div className="flex flex-col w-full">
        <label className=" text-[13px] font-medium leading-5 text-slate-700">
          Онцгой амжилт гаргасан бол түүнийг гэрчлэх баримт бичиг, шийдвэрийн
          хуулбар
        </label>
        <Upload
          onChange={(info) => handleUploadChange(info, "shiidverHuulbar")}
          name="file"
          action={UploadFile()}
          headers={{
            Authorization: `Bearer ${token}`,
          }}
          multiple
          className="border bg-white/5 rounded-lg w-full h-5/6 py-2 px-3 mt-4 text-center "
        >
          <div className="flex flex-col m-0 justify-center items-center gap-4 pb-2">
            <img src={uploadbg} alt="uploadicon" />
            <span className="text-[14px] font-normal leading-5 text-slate-500 text-center">
              Файл хуулах
            </span>
          </div>
        </Upload>
      </div>

      <Button type="primary" onClick={handlePrev} style={{ marginTop: 16 }}>
        Back
      </Button>
      <Button type="primary" onClick={handleSubmit} style={{ marginTop: 16 }}>
        Submit
      </Button>
    </div>
  );
}
