let requestData = {
  infoData: [],
  awardHistory: [],
  workplace: [],
  awardFiles: [],
};
export function saveRequestData(data) {
  requestData = { ...requestData, ...data };
}

export function getRequestData() {
  return requestData;
}

export function clearRequestData() {
  requestData = {};
}
