import axios from "axios";

export const BASE_URL = "https://st-awards.gov.mn";
export const rest = "/rest/service";

axios.defaults.withCredentials = true;
const token = localStorage.getItem("token");
let newToken;
if (token) {
  newToken = token.split(" ")[0].replace(/"/g, "");
} else {
  //
}
export async function getAwardOrgs() {
  try {
    const response = await fetch(
      `${BASE_URL}${rest}/organization/isgiveawardorg`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${newToken}`,
        },
      }
    );
    const data = response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getOrganizations() {
  try {
    const response = await fetch(`${BASE_URL}${rest}/organization`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getAwards() {
  try {
    const response = await fetch(`${BASE_URL}${rest}/ref/award`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getArchives() {
  try {
    const response = await fetch(`${BASE_URL}${rest}/archive`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });

    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function handleLogin({ username, password }) {
  try {
    const response = await fetch(`${BASE_URL}/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
      credentials: "include",
    });
    console.log(response.status);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function handleLogout() {
  try {
    const response = await fetch(`${BASE_URL}${rest}/user/logout`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
      credentials: "include",
    });
    console.log(response.status);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function registerArchive({
  org,
  award,
  register,
  firstName,
  lastName,
  licenseNum,
  orgName,
  orderDate,
  orderNum,
  phoneNumber,
  email,
  position,
  description,
  age,
  gender,
  divisionId,
  occupationId,
  workYear,
  workDevisionYear,
}) {
  try {
    const response = await fetch(`${BASE_URL}${rest}/archive`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
      body: JSON.stringify({
        awardOrgId: org,
        awardId: award,
        register: register,
        firstName: firstName,
        lastName: lastName,
        passportNumber: licenseNum,
        orgName: orgName,
        tushaalDate: orderDate,
        tushaalName: orderNum,
        phone: phoneNumber,
        email: email,
        position: position,
        description: description,
        age: age,
        gender: gender,
        divisionId: divisionId,
        occupationId: occupationId,
        workYear: workYear,
        workDevisionYear: workDevisionYear,
      }),
      credentials: "include",
    });
    console.log(response.status);
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function registerOrg({
  orgName,
  register,
  phone,
  email,
  isGiveAward,
}) {
  try {
    const response = await fetch(`${BASE_URL}${rest}/organization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
      body: JSON.stringify({
        name: orgName,
        register: register,
        phone: phone,
        email: email,
        isGiveAward: isGiveAward,
      }),
      credentials: "include",
    });
    console.log(response, "fetch function response");
    return response.json();
  } catch (error) {
    throw error;
  }
}
export async function getSingleOrg({ id }) {
  try {
    const response = await fetch(`${BASE_URL}${rest}/organization/one/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function updateOrg({
  id,
  orgName,
  register,
  phone,
  email,
  isGiveAward,
}) {
  try {
    const response = await fetch(`${BASE_URL}${rest}/organization/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
      body: JSON.stringify({
        name: orgName,
        register: register,
        phone: phone,
        email: email,
        isGiveAward: isGiveAward,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating organization:", error);
    throw error;
  }
}

export async function getSingleArchive({ id }) {
  try {
    const response = await fetch(`${BASE_URL}${rest}/archive/one/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function updateArchive({
  id,
  org,
  award,
  register,
  firstName,
  lastName,
  licenseNum,
  orgName,
  orderDate,
  orderNum,
  phoneNumber,
  email,
  position,
  description,
}) {
  console.log("Org:", org, "Award:", award);

  try {
    const response = await fetch(`${BASE_URL}${rest}/archive/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
      body: JSON.stringify({
        awardOrgId: org,
        awardId: award,
        register: register,
        firstName: firstName,
        lastName: lastName,
        passportNumber: licenseNum,
        orgName: orgName,
        tushaalDate: orderDate,
        tushaalName: orderNum,
        phone: phoneNumber,
        email: email,
        position: position,
        description: description,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating organization:", error);
    throw error;
  }
}

export async function deleteSingleArchive(id) {
  try {
    const response = await fetch(`${BASE_URL}${rest}/archive/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });

    return response.json();
  } catch (error) {
    console.error("Error updating organization:", error);
    throw error;
  }
}

export async function getDivisions() {
  try {
    const response = await fetch(`${BASE_URL}${rest}/ref-division`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getOccupations() {
  try {
    const response = await fetch(`${BASE_URL}${rest}/ref-occupation`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getGenders() {
  try {
    const response = await fetch(`${BASE_URL}${rest}/api/gender`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

// export async function handleLogin({ username, password }) {
//   try {
//     const response = await fetch(`${BASE_URL}/user/login`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         username: username,
//         password: password,
//       }),
//       credentials: "include",
//     });
//     console.log(response.status);
//     return response;
//   } catch (error) {
//     throw error;
//   }
// }

export async function updateAward({ name, orgId, id }) {
  try {
    const response = await fetch(`${BASE_URL}${rest}/ref/award/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
      body: JSON.stringify({
        name: name,
        orgId: orgId,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating organization:", error);
    throw error;
  }
}

export async function getSingleAward({ id }) {
  try {
    const response = await fetch(`${BASE_URL}${rest}/ref/award/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function createAward({ name, orgId }) {
  try {
    const response = await fetch(`${BASE_URL}${rest}/ref/award`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
      body: JSON.stringify({
        name,
        organization: {
          id: orgId,
        },
      }),
    });

    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function deleteAward(id) {
  try {
    const response = await fetch(`${BASE_URL}${rest}/ref/award/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function getSubOrgs() {
  try {
    const response = await fetch(`${BASE_URL}${rest}/sub-organization`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function createSubOrg({
  name,
  register,
  isNoRegister,
  email,
  phone,
  orgId,
}) {
  try {
    const response = await fetch(`${BASE_URL}${rest}/sub-organization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
      body: JSON.stringify({
        name,
        register,
        isNoRegister,
        email,
        phone,
        orgId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getSingleSubOrg({ id }) {
  try {
    const response = await fetch(`${BASE_URL}${rest}/ref/award/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function updateSubOrg({
  id,
  orgName,
  register,
  phone,
  email,
  isGiveAward,
}) {
  try {
    const response = await fetch(`${BASE_URL}${rest}/organization/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
      body: JSON.stringify({
        name: orgName,
        register: register,
        phone: phone,
        email: email,
        isGiveAward: isGiveAward,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating organization:", error);
    throw error;
  }
}

export async function getEduTypes() {
  try {
    const response = await fetch(`${BASE_URL}${rest}/ref/educationtype`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching education types:", error);
    throw error;
  }
}

// export async function newRequest(data) {
//   try {
//     const response = await fetch(`${BASE_URL}${rest}/award/request`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${newToken}`,
//       },
//       body: JSON.stringify(data),
//     });

//     return response;
//   } catch (error) {
//     throw error;
//   }
// }

export async function newRequest(data) {
  try {
    const response = await fetch(`${BASE_URL}${rest}/award/request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
      body: JSON.stringify(data),
    });
    console.log(response.status);
    return response;
  } catch (error) {
    throw error;
  }
}

export function UploadFile() {
  const url = `${BASE_URL}${rest}/award/request/oneFile`;
  return url;
}

export async function getZahirgaa() {
  try {
    const response = await fetch(`${BASE_URL}${rest}/ref/citizenship`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
      // body: JSON.stringify(data),
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function checkAuthenticated() {
  try {
    const response = await fetch(`${BASE_URL}${rest}/user/check-auth`, {
      method: "GET",
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getRequests() {
  try {
    const response = await fetch(`${BASE_URL}${rest}/award/request`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
export async function getSingleRequest(id) {
  try {
    const response = await fetch(`${BASE_URL}${rest}/award/request/one/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${newToken}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
