import React from "react";
import { Button } from "@headlessui/react";
import Lottie from "lottie-react";
import animation from "../assets/animation.json";
import { useNavigate } from "react-router-dom";

const SuccessfulStep = ({ path }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (path) {
      // window.location.href(path);
      navigate(path);
    } else {
      window.location.reload();
    }
  };
  return (
    <div className="relative flex flex-col items-center justify-center ">
      <span className=" w-80 h-80">
        <Lottie animationData={animation} loop={true} autoplay={true} />
      </span>

      <span className="text-green-800 text-base font-medium">
        Мэдээллийг амжилттай бүртгэлээ
      </span>
      <Button
        onClick={handleClick}
        className="mt-6 text-white py-2 px-4 bg-[#2c73eb] hover:bg-[#2c73eb] items-center gap-2 rounded-lg  text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
      >
        Шинэ бүртгэл үүсгэх
      </Button>
    </div>
  );
};

export default SuccessfulStep;
