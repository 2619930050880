import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, rest } from "../services";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const userProf = localStorage.getItem("user");
    if (userProf) {
      return JSON.parse(userProf);
    }
    return null;
  });

  const login = async (payload) => {
    try {
      const response = await axios.post(
        `${BASE_URL}${rest}/user/login`,
        payload,
        {
          withCredentials: true,
        }
      );
      setUser(response.data.user);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("token", response.data.access_token);
    } catch (error) {
      //
    }
  };
  const logout = async () => {
    try {
      const response = await axios.delete(`${BASE_URL}${rest}/user/logout`);
      localStorage.removeItem("user");
      setUser(null);
      localStorage.removeItem("token");
      localStorage.clear();
    } catch (error) {
      //
    }
  };

  return (
    <AuthContext.Provider value={{ login, user, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
