import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import {
  Button,
  Field,
  Fieldset,
  Input,
  Label,
  Select,
} from "@headlessui/react";
import { cn } from "../lib/utils";
import { useEffect, useState } from "react";
import {
  createAward,
  createSubOrg,
  getOrganizations,
  getSingleSubOrg,
  updateSubOrg,
} from "../services";
import { useNavigate } from "react-router-dom";
import { message, Radio } from "antd";
import AuthContext from "../context/AuthContext";
import { FaChevronDown } from "react-icons/fa";

export default function EditSubOrg() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [orgs, setOrgs] = useState();
  const [value, setValue] = useState();
  const [parentId, setParentId] = useState(1);
  const [formValues, setFormValues] = useState({
    name: "",
    register: "",
    isNoRegister: value,
    email: "",
    phone: "",
    orgId: parentId,
  });
  const navigate = useNavigate();

  function getSingleOrg() {
    getSingleSubOrg({ id })
      .then((response) => {
        const orgData = response.data;
        setFormValues({
          name: orgData.name || "",
          register: orgData.register || "",
          phone: orgData.phone || "",
          email: orgData.email || "",
          isNoRegister: orgData.isNoRegister || false,
          orgId: orgData.orgId || false,
        });
      })
      .catch((error) => {
        console.error("Error fetching organization", error);
      });
  }

  function getParentOrgs() {
    getOrganizations()
      .then((response) => {
        setOrgs(response.data);
      })
      .catch((error) => {
        console.log("error:", error);
      });
  }

  useEffect(() => {
    getParentOrgs();
    getSingleOrg();
  }, []);

  // function addSubOrg() {
  //   return createSubOrg(formValues)
  //     .then((response) => {
  //       if (response.result) {
  //         message.success("Шагнал амжилттай нэмэгдлээ");
  //         navigate("/suborgs");
  //       } else {
  //         console.error(response.message);
  //         message.error(response.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const onChange = (e) => {
    const isNoRegister = e.target.value === "true";
    setFormValues({
      ...formValues,
      isNoRegister,
    });
  };
  const handleSelectChange = (e) => {
    const { value } = e.target;
    setParentId(value);
  };

  async function EditSubOrg() {
    try {
      const response = await updateSubOrg({ ...formValues, id });

      if (response.result) {
        console.log("successfully created ");
        message.success("Засвар амжилттай хийгдлээ");
        navigate("/awards");
      } else {
        message.error("Засвар хийхэд алдаа гарлаа");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Алдаа гарлаа. Та дахин оролдоно уу.");
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    await EditSubOrg();
  };
  return (
    <Layout>
      <Header title="Салбар байгууллага засах" />
      <div className="p-6">
        <form onSubmit={(e) => handleSubmit(e)}>
          <Fieldset className="gap-6 rounded-xl bg-white/5 ">
            <div className="w-full grid grid-cols-2 gap-10">
              <Field className="flex flex-col gap-5">
                <Label>Регистртэй байгууллага эсэх</Label>
                <Radio.Group
                  onChange={onChange}
                  value={formValues.isNoRegister}
                  name="isNoRegister"
                >
                  <Radio value={true}>Тийм</Radio>
                  <Radio value={false}>Үгүй</Radio>
                </Radio.Group>
              </Field>
              <Field>
                <Label>Байгууллагын нэр</Label>
                <Input
                  name="name"
                  value={formValues.name}
                  placeholder="Утга бичнэ үү"
                  onChange={handleChange}
                  className="inputStyle"
                />
              </Field>
              {value && (
                <Field>
                  <Label>Байгууллагын регистр</Label>
                  <Input
                    name="register"
                    value={formValues?.register || ""}
                    placeholder="Утга бичнэ үү"
                    onChange={handleChange}
                    className="inputStyle"
                  />
                </Field>
              )}
              <div className="relative">
                <Label>Байгууллага</Label>
                <Select
                  name="orgId"
                  value={formValues.orgId}
                  onChange={(e) => {
                    handleSelectChange(e);
                  }}
                  className={cn(
                    "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                    " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                >
                  <option value="">Харьяалагдах яам сонгоно уу</option>
                  {orgs &&
                    orgs.map((org) => (
                      <option value={org.id} key={org.id}>
                        {org.name}
                      </option>
                    ))}
                </Select>
                <FaChevronDown
                  color="#475569"
                  className=" group pointer-events-none absolute top-[52px] right-3 size-3 "
                />
              </div>
              <Field>
                <Label>Цахим шуудангийн хаяг </Label>
                <Input
                  name="email"
                  value={formValues.email}
                  type="email"
                  placeholder="Утга бичнэ үү"
                  onChange={handleChange}
                  className="inputStyle"
                />
              </Field>
              <Field>
                <Label>Холбогдох утасны дугаар</Label>
                <Input
                  name="phone"
                  value={formValues.phone}
                  placeholder="Утга бичнэ үү"
                  onChange={handleChange}
                  className="inputStyle"
                />
              </Field>
            </div>
            <Button
              type="submit"
              className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none  data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
            >
              Хадгалах
            </Button>
          </Fieldset>
        </form>
      </div>
    </Layout>
  );
}
