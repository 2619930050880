import React, { useState } from "react";
import { ConfigProvider, Steps } from "antd";
import SuccessfulStep from "../components/SuccessfulStep";
import RequestForm from "../components/Requests/NewReqForm";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import AttachmentForm from "../components/Requests/AttachmentForm";

export default function RequestCreate() {
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    { title: "Мэдээлэл", content: <RequestForm next={next} /> },
    {
      title: "Хавсралт файл",
      content: <AttachmentForm next={next} prev={prev} />,
    },
    { title: "Илгээх", content: <SuccessfulStep path="/" /> },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  return (
    <>
      <Layout>
        <Header title="Шагнал горилогчийн мэдээлэл илгээх" />
        <div className="p-6">
          <div className="flex flex-col items-center">
            <ConfigProvider
              theme={{
                components: {
                  Steps: {
                    iconSize: 40,
                  },
                },
              }}
            >
              <Steps
                style={{ width: "550px" }}
                current={current}
                labelPlacement="vertical"
                className="font-semibold"
                items={items}
              />
            </ConfigProvider>
            <div className="w-full mt-5">{steps[current].content}</div>
          </div>
        </div>
      </Layout>
    </>
  );
}
