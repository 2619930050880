import { Button, Form, message, Upload, DatePicker } from "antd";
import React from "react";
import { CiCircleMinus } from "react-icons/ci";
import { Field, Fieldset, Input, Label, Textarea } from "@headlessui/react";
import { cn } from "../../lib/utils";
import { FaChevronDown } from "react-icons/fa";
import {
  getAwards,
  getAwardOrgs,
  getDivisions,
  getGenders,
  getEduTypes,
  UploadFile,
  getZahirgaa,
  getOccupations,
} from "../../services";
import { useEffect, useState } from "react";
import userprofile from "../../assets/user.svg";
import { getRequestData, saveRequestData } from "../../stores/formDataStore";

export default function RequestForm({ next }) {
  const [awards, setAwards] = useState();
  const [awardOrgs, setAwardOrgs] = useState();
  const [divisions, setDivisions] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [gender, setGender] = useState([]);
  const [eduTypes, setEduTypes] = useState([]);
  const [zahirgaa, setZahirgaa] = useState([]);
  const [infoData, setInfoData] = useState({
    awardOrgId: null,
    awardId: null,
    lastName: "",
    firstName: "",
    birthDate: null,
    register: "",
    genderId: null,
    educationTypeId: null,
    occupationId: null,
    divisionId: null,
    workDevisionYear: null,
    workYear: null,
    position: "",
    description: "",
    percent: null,
    citizenshipId: null,
    picture: "",
  });
  const [workPlace, setWorkplace] = useState([
    {
      workName: "",
      workPosition: "",
      startDate: null,
      endDate: null,
    },
  ]);
  const [history, setHistory] = useState([
    {
      awardName: "",
      giveDate: null,
    },
  ]);

  function fetchAwards() {
    getAwards()
      .then((response) => {
        setAwards(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchZahirgaa() {
    getZahirgaa()
      .then((response) => {
        setZahirgaa(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchDivision() {
    getDivisions()
      .then((response) => {
        setDivisions(response);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchGenders() {
    getGenders()
      .then((response) => {
        if (response) {
          setGender(response);
          setLoading(false);
          console.log("gender", gender);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function fetchOccupations() {
    getOccupations()
      .then((response) => {
        setOccupations(response);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchOrgs() {
    getAwardOrgs()
      .then((response) => {
        console.log("responseORGS", response);
        setAwardOrgs(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchEduTypes() {
    getEduTypes()
      .then((response) => {
        setEduTypes(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  useEffect(() => {
    fetchOrgs();
    fetchAwards();
    fetchDivision();
    fetchGenders();
    fetchEduTypes();
    fetchZahirgaa();
    fetchOccupations();
  }, []);

  const handleInfoDataChange = (e) => {
    const { name, value, type } = e.target;
    const parsedValue = type === "number" ? parseFloat(value) || 0 : value;

    setInfoData((prev) => ({
      ...prev,
      [name]: parsedValue,
    }));
  };

  // const numericFields = [
  //   "awardOrgId",
  //   "awardId",
  //   "educationTypeId",
  //   "administration",
  //   "divisionId",
  // ];

  const handleSelectChange = (e, fieldName) => {
    const { value } = e.target;

    setInfoData((prevState) => ({
      ...prevState,
      [fieldName]: isNaN(value) ? value : Number(value),
    }));
  };

  const handleUploadChange = (info) => {
    const { status } = info.file;

    if (status === "done") {
      if (info.file.response && info.file.response.result === false) {
        message.error(info.file.response.message);
      } else {
        setInfoData((prevData) => ({
          ...prevData,
          picture: {
            name: "picture",
            fileName: info.file.response.filename,
            type: info.file.response.mimetype,
            url: info.file.response.path,
            size: info.file.response.size,
          },
        }));
        message.success("File uploaded successfully!");
      }
    } else if (status === "error") {
      message.error("File upload failed.");
    }
  };

  const handleWorkChange = (index, e) => {
    const { name, value } = e.target;
    setWorkplace((prev) => {
      const updatedWorkplace = [...prev];
      updatedWorkplace[index] = {
        ...updatedWorkplace[index],
        [name]: value,
      };
      return updatedWorkplace;
    });
  };

  const handleHistoryChange = (index, e) => {
    const { name, value } = e.target;
    setHistory((prev) => {
      const updatedHistory = [...prev];
      updatedHistory[index] = {
        ...updatedHistory[index],
        [name]: value,
      };
      return updatedHistory;
    });
  };

  const handleDateChange = (fieldName, index, date, dateString) => {
    if (fieldName === "birthDate") {
      setInfoData((prev) => ({
        ...prev,
        birthDate: dateString,
      }));
    } else if (fieldName === "giveDate") {
      setHistory((prev) => {
        const updatedHistory = [...prev];
        updatedHistory[index] = {
          ...updatedHistory[index],
          giveDate: dateString,
        };
        return updatedHistory;
      });
    } else {
      setWorkplace((prev) => {
        const updatedWorkplace = [...prev];
        updatedWorkplace[index] = {
          ...updatedWorkplace[index],
          [fieldName]: dateString,
        };
        return updatedWorkplace;
      });
    }
  };

  const token = localStorage.getItem("token");
  const onSubmit = () => {
    saveRequestData({ infoData, awardHistory: history, workplace: workPlace });
    const datas = getRequestData();
    console.log("datas", datas);
    next();
  };
  return (
    <>
      <Form onFinish={onSubmit}>
        <Fieldset className="gap-6 rounded-xl bg-white/5 ">
          {/* SHAGNAL SONGOH */}
          <div className="flex flex-col gap-4 pb-5 ">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <span className=" text-[#2C73EB] font-medium text-base">
                  ШАГНАЛ СОНГОХ:
                </span>
                <div className="border-b-2 border-[#2C73EB]"></div>
              </div>

              <div className="w-full grid grid-cols-4 gap-6">
                <Field>
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Харьяалагдах яам
                  </Label>
                  <div className="relative">
                    <select
                      name="awardOrgId"
                      className={cn(
                        "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                        " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                      onChange={(e) => handleSelectChange(e, "awardOrgId")}
                    >
                      <option value="">Харьяалагдах яам сонгоно уу</option>
                      {awardOrgs &&
                        awardOrgs.map((awardOrg) => (
                          <option value={awardOrg.id} key={awardOrg.id}>
                            {awardOrg.name}
                          </option>
                        ))}
                    </select>
                    <FaChevronDown
                      color="#475569"
                      className=" group pointer-events-none absolute top-4 right-3 size-3 "
                    />
                  </div>
                </Field>
                <Field>
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Шагналын нэр
                  </Label>
                  <div className="relative">
                    <select
                      className={cn(
                        "mt-3 text-[#475569] block w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                        " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                      name="awardId"
                      onChange={(e) => handleSelectChange(e, "awardId")}
                    >
                      <option value="">Шагнал сонгоно уу</option>
                      {awards &&
                        awards.map((award) => (
                          <option value={award.id} key={award.id}>
                            {award.name}
                          </option>
                        ))}
                    </select>
                    <FaChevronDown
                      color="#475569"
                      className=" group pointer-events-none absolute top-4 right-3 size-3 "
                    />
                  </div>
                </Field>
              </div>
            </div>
          </div>
          {/* HUVIIN MEDEELEL */}
          <div className="flex flex-col gap-4 pb-5">
            <div className="flex flex-col gap-2">
              <span className=" text-[#2C73EB] font-medium text-base ">
                ХУВИЙН МЭДЭЭЛЭЛ:
              </span>
              <div className="border-b-2 border-[#2C73EB]"></div>
            </div>
            <div className="w-full flex justify-between gap-11 ">
              <Upload
                className=" border bg-white/5 rounded-lg h-5/6 py-2 px-3 mt-10"
                action={UploadFile()}
                headers={{
                  Authorization: `Bearer ${token}`,
                }}
                name="file"
                onChange={handleUploadChange}
              >
                <div className="flex flex-col items-center gap-4 pb-2">
                  <img src={userprofile} alt="aaa" />
                  <span className="text-[13px] font-normal leading-5 text-slate-500 text-center">
                    Энд дарж хуулна уу
                  </span>
                </div>
              </Upload>

              <div className="w-full  grid grid-cols-3 gap-6">
                <Field className="w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Эцэг эхийн нэр
                  </Label>

                  <Input
                    name="lastName"
                    onChange={handleInfoDataChange}
                    placeholder="Утга бичнэ үү"
                    className="inputStyle "
                  />
                </Field>
                <Field className="w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Өөрийн нэр
                  </Label>

                  <Input
                    name="firstName"
                    placeholder="Утга бичнэ үү"
                    required={true}
                    className="inputStyle"
                    onChange={handleInfoDataChange}
                  />
                </Field>

                <Field className="w-10/12 flex flex-col">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Төрсөн огноо
                  </Label>

                  <DatePicker
                    placeholder="Огноо сонгоно уу"
                    onChange={(date, dateString, index) =>
                      handleDateChange("birthDate", index, date, dateString)
                    }
                    // format="YYYY-MM-DD"

                    className="inputStyle"
                  />
                </Field>
                <Field className="w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Регистрийн дугаар
                  </Label>

                  <Input
                    placeholder="Утга бичнэ үү"
                    name="register"
                    required={true}
                    className="inputStyle"
                    onChange={handleInfoDataChange}
                    minLength={10}
                  />
                </Field>
                <Field className="relative w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Хүйс
                  </Label>
                  <select
                    name="genderId"
                    className={cn(
                      "mt-3 block text-[#475569] w-full relative appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    onChange={(e) => handleSelectChange(e, "genderId")}
                  >
                    <option value="">Хүйс сонгоно уу</option>
                    {gender &&
                      gender.data &&
                      gender.data.map((choice) => (
                        <option value={choice.id} key={choice.name}>
                          {choice.name}
                        </option>
                      ))}
                  </select>
                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-[52px] right-3 size-3 "
                  />
                </Field>
                <Field className="relative w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Боловсрол
                  </Label>
                  <select
                    name="educationTypeId"
                    className={cn(
                      "mt-3 block text-[#475569] w-full relative appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    onChange={(e) => handleSelectChange(e, "educationTypeId")}
                  >
                    <option value="">Боловсролын түвшин сонгоно уу</option>
                    {eduTypes &&
                      eduTypes.map((choice) => (
                        <option value={choice.id} key={choice.id}>
                          {choice.name}
                        </option>
                      ))}
                  </select>
                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-[52px] right-3 size-3 "
                  />
                </Field>
                <Field className="relative w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Мэргэжил
                  </Label>
                  <select
                    name="occupationId"
                    onChange={(e) => handleSelectChange(e, "occupationId")}
                    className={cn(
                      "mt-3 block text-[#475569] w-full relative appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                  >
                    <option value="">Мэргэжил сонгоно уу</option>
                    {occupations &&
                      occupations.data &&
                      occupations.data.map((choice) => (
                        <option value={choice.id} key={choice.name}>
                          {choice.name}
                        </option>
                      ))}
                  </select>
                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-[52px] right-3 size-3 "
                  />

                  {/* <Input
                    placeholder="Утга бичнэ үү"
                    name="occupation"
                    className="inputStyle"
                    onChange={handleInfoDataChange}
                  /> */}
                </Field>
                <Field className="relative w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Үндсэн захиргаа
                  </Label>
                  <select
                    name="citizenshipId"
                    className={cn(
                      "mt-3 block text-[#475569] w-full relative appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    onChange={(e) => handleSelectChange(e, "citizenshipId")}
                  >
                    <option value="">Захиргаа сонгоно уу</option>
                    {zahirgaa &&
                      zahirgaa.map((choice) => (
                        <option value={choice.id} key={choice.name}>
                          {choice.name}
                        </option>
                      ))}
                  </select>

                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-[52px] right-3 size-3"
                  />
                </Field>
                <Field className="relative w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Ажилладаг салбар
                  </Label>
                  <select
                    name="divisionId"
                    className={cn(
                      "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    onChange={(e) => handleSelectChange(e, "divisionId")}
                  >
                    <option value="">Ажилладаг салбар сонгоно уу</option>
                    {divisions &&
                      divisions.data &&
                      divisions.data.map((choice) => (
                        <option value={choice.id} key={choice.name}>
                          {choice.name}
                        </option>
                      ))}
                  </select>
                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-[52px] right-3 size-3 "
                  />
                </Field>
                <Field className="w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Салбартаа ажилласан жил
                  </Label>
                  <Input
                    placeholder="Утга бичнэ үү"
                    type="number"
                    name="workDevisionYear"
                    className="inputStyle"
                    onChange={handleInfoDataChange}
                  />
                </Field>
                <Field className="w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Улсад нийт ажилласан жил
                  </Label>
                  <Input
                    placeholder="Утга бичнэ үү"
                    type="number"
                    name="workYear"
                    className="inputStyle"
                    onChange={handleInfoDataChange}
                  />
                </Field>
                <Field className="w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Одоо хашиж буй албан тушаал
                  </Label>
                  <Input
                    placeholder="Утга бичнэ үү"
                    name="position"
                    className="inputStyle"
                    onChange={handleInfoDataChange}
                  />
                </Field>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4 pb-5">
            <div className="flex flex-col gap-2">
              <span className=" text-[#2C73EB] font-medium text-base ">
                УРЬД БОЛОН ОДОО АЖИЛЛАЖ БАЙГАА ГАЗАР:
              </span>
              <div className="border-b-2 border-[#2C73EB]"></div>
            </div>
            <Fieldset>
              <Form.List name="workplace" className="">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <div
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          alignItems: "center",
                          gap: 30,
                        }}
                      >
                        <Form.Item {...restField} className="w-full">
                          <Label className="text-sm/6 font-medium text-slate-700">
                            Ажлын газрын нэр
                          </Label>
                          <Input
                            name="workName"
                            placeholder="Утга бичнэ үү"
                            className={"inputStyle"}
                            onChange={(e) => handleWorkChange(index, e)}
                          />
                        </Form.Item>
                        <Form.Item {...restField} className="w-full">
                          <Label className="text-sm/6 font-medium text-slate-700">
                            Эрхэлсэн ажил
                          </Label>
                          <Input
                            onChange={(e) => handleWorkChange(index, e)}
                            name="workPosition"
                            placeholder="Утга бичнэ үү"
                            className="inputStyle"
                          />
                        </Form.Item>
                        <Form.Item {...restField} className="w-full">
                          <Label className="text-sm/6 font-medium text-slate-700">
                            Ажиллаж эхэлсэн огноо
                          </Label>
                          <DatePicker
                            placeholder="Огноо сонгоно уу"
                            onChange={(date, dateString) =>
                              handleDateChange(
                                "startDate",
                                index,
                                date,
                                dateString
                              )
                            }
                            className="inputStyle"
                            format="YYYY-MM-DD"
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name="endDate"
                          className="w-full"
                        >
                          <Label className="text-sm/6 font-medium text-slate-700">
                            Ажлаас гарсан огноо
                          </Label>
                          <DatePicker
                            placeholder="Огноо сонгоно уу"
                            onChange={(date, dateString) =>
                              handleDateChange(
                                "endDate",
                                index,
                                date,
                                dateString
                              )
                            }
                            format="YYYY-MM-DD"
                            className="inputStyle"
                          />
                        </Form.Item>
                        <CiCircleMinus onClick={() => remove(name)} size={70} />
                      </div>
                    ))}
                    <Form.Item className="w-[10%]">
                      <Button
                        onClick={() => add()}
                        block
                        className="border  border-[#166534] bg-[#F0FDF4] rounded-lg text-sm font-medium leading-6 text-green-900"
                      >
                        Мөр нэмэх
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Fieldset>
          </div>
          <div className="flex flex-col gap-4 pb-5">
            <div className="flex flex-col gap-2">
              <span className=" text-[#2C73EB] font-medium text-base ">
                ТӨР, ЗАСГИЙН БОЛОН БУСАД ЯМАР ШАГНАЛААР ХЭЗЭЭ ШАГНАГДСАН:
              </span>
              <div className="border-b-2 border-[#2C73EB]"></div>
            </div>
            <Fieldset>
              <Form.List name="awardHistory" className="">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <div
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          alignItems: "center",
                          gap: 40,
                        }}
                      >
                        <Form.Item {...restField} className="w-full">
                          <Label className="text-sm/6 font-medium text-slate-700">
                            Шагналын нэр
                          </Label>
                          <Input
                            placeholder="Утга бичнэ үү"
                            className="inputStyle"
                            name="awardName"
                            onChange={(e) => handleHistoryChange(index, e)}
                          />
                        </Form.Item>
                        <Form.Item {...restField} className="w-4/12">
                          <Label className="text-sm/6 font-medium text-slate-700">
                            Шагнагдсан огноо
                          </Label>

                          <DatePicker
                            placeholder="Огноо сонгоно уу"
                            onChange={(date, dateString) =>
                              handleDateChange(
                                "giveDate",
                                index,
                                date,
                                dateString
                              )
                            }
                            className="inputStyle"
                            format="YYYY-MM-DD"
                          />
                        </Form.Item>

                        <CiCircleMinus
                          onClick={() => remove(name)}
                          size={30}
                          color="red"
                        />
                      </div>
                    ))}
                    <Form.Item className="w-[10%]">
                      <Button
                        onClick={() => add()}
                        block
                        className="border  border-[#166534] bg-[#F0FDF4] rounded-lg text-sm font-medium leading-6 text-green-900"
                      >
                        Мөр нэмэх
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Fieldset>
          </div>
          <div className="flex flex-col gap-4 pb-5">
            <div className="flex flex-col gap-2">
              <span className=" text-[#2C73EB] font-medium text-base ">
                Бусад:
              </span>
              <div className="border-b-2 border-[#2C73EB]"></div>
              <div>
                <Field className="">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Шагнуулах гэж байгаа үндэслэл /тухайн хүний ажлын амжилт,
                    гаргасан үр дүн, туурвисан бүтээл зэргийг сүүлийн 5 жилээр
                    тодорхой бичиж, шаардлагатай гэж үзвэл холбогдох жагсаалтыг
                    хавсаргана/
                  </Label>

                  <Textarea
                    placeholder="Утга бичнэ үү"
                    name="description"
                    onChange={handleInfoDataChange}
                    className={cn(
                      "mt-3 block w-full resize-none rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    rows={3}
                  />
                </Field>
                <Field className="w-2/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Шагналд нэр дэвшүүлэхийг дэмжсэн хамт олны саналын хувь:
                  </Label>
                  <Input
                    name="percent"
                    type="number"
                    onChange={handleInfoDataChange}
                    placeholder="Утга бичнэ үү"
                    required={true}
                    className="inputStyle "
                  />
                </Field>
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-5">
            <button
              type="submit"
              className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
            >
              Дараагийн алхам
            </button>
          </div>
        </Fieldset>
      </Form>
    </>
  );
}
