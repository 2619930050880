import React, { useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import { deleteAward, getAwards, getRequests } from "../services";
import { ConfigProvider, Table, message } from "antd";
import { useNavigate } from "react-router-dom";
import { MdOutlineEdit, MdDelete } from "react-icons/md";
import { PiDotsThreeCircleFill } from "react-icons/pi";
import plus from "../assets/plus-sign.svg";
import moment from "moment";
import { Link } from "react-router-dom";

export default function Requests() {
  const [requests, setRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showMore, setShowMore] = useState();
  async function fetchAwards() {
    const response = await getRequests();
    console.log("response", response);
    setRequests(response.data);
  }
  useEffect(() => {
    fetchAwards();
  }, []);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setShowMore(!showMore);
  };

  const statusColors = {
    Шагнагдсан: "bg-green-500 text-white",
    Илгээсэн: "bg-[#2c73eb] text-white",
    Татгалзсан: "bg-red-600 text-white",
    // canceled: "bg-gray-100 text-gray-800",
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Овог",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Нэр",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Шагнал",
      key: "awardTable",
      dataIndex: "awardTable",
      render: (_, record) => record.awardTable?.name,
    },
    {
      title: "Төлөв",
      key: "awardState",
      dataIndex: "awardState",
      render: (_, record) => (
        <div
          className={`py-1 px-3 w-[100px] ${
            statusColors[record.awardState?.stateName] || " "
          }
           m-0 text-center text-white rounded-xl font-semibold text-[12px]`}
        >
          {record.awardState?.stateName}
        </div>
      ),
    },

    {
      title: "Илгээсэн",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
  ];
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  return (
    <>
      <Layout>
        <Header title="Хүсэлтийн жагсаалт" />
        <div className="p-6 flex flex-col gap-6">
          <span className="flex justify-end items-center">
            <Link
              to="/requests/create"
              className="pl-4 flex items-center gap-[10px] pr-5 py-2 text-white bg-[#2C73EB] rounded-xl  text-center"
            >
              <img src={plus} alt="" />
              <span className="text-sm font-bold">Шинэ бүртгэл</span>
            </Link>
          </span>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: "Golos text",
                colorText: "#334155",
                fontWeightStrong: 600,
              },
            }}
          >
            <Table
              columns={columns}
              dataSource={requests}
              scroll={{ x: "100%" }}
              rowKey="id"
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                onChange: handlePageChange,
                showSizeChanger: true,
                position: ["bottomLeft"],
                locale: { items_per_page: "" },
                pageSizeOptions: ["5", "10", "20", "50"],
                onShowSizeChange: (current, size) => setPageSize(size),
              }}
            />
          </ConfigProvider>
        </div>
      </Layout>
    </>
  );
}
